import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';
//import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { Ng2CompleterModule } from "@tibelian/ng2-completer";
import { TextAnalysisComponent } from './text-analysis.component';
import { CriteriaComponent } from './criteria/criteria.component';
import { ProfileComponent } from './profile/profile.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TextHighlightComponent } from './text-highlight/text-highlight.component';
import { AnalysisResultComponent } from './analysis-result/analysis-result.component';
//import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { CompareComponent } from './compare.component';
import { CompareResultComponent } from './compare-result/compare-result.component';
import { ProfileResizeDirective } from './profile/profile.resize.directive';

@NgModule({
  declarations: [
    TextAnalysisComponent,
    CriteriaComponent,
    ProfileComponent,
    TextInputComponent,
    TextHighlightComponent,
    AnalysisResultComponent,
    //RadarChartComponent,
    CompareComponent,
    CompareResultComponent,
    ProfileResizeDirective
  ],
  imports: [
    SharedModule,
    FontAwesomeModule,
    AngularEditorModule,
    TooltipModule,
    //ChartsModule,
    FormsModule,
    Ng2CompleterModule
  ],
  exports: [ProfileResizeDirective]
})
export class TextAnalysisModule { }
